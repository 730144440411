import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  CreateSourceTypeRequest,
  GetSourceTypeRequest,
  RemoveSourceTypeRequest,
  RemoveSourceTypeResponse,
  SourceTypeListResponse,
  SourceTypeResponse,
  UpdateSourceTypeRequest,
  UpdateSourceTypeStatusRequest,
  UpdateSourceTypeStatusResponse
} from '../models/source-type';

@Injectable({
  providedIn: 'root'
})
export class SourceTypeService {
  constructor(private http: HttpClient) { }

  getList(): Observable<SourceTypeListResponse> {
    return this.http.post<SourceTypeListResponse>('/api/v1/sourceType/getSourceTypeList', {});
  }

  get(domain: string): Observable<SourceTypeResponse> {
    const getSourceTypeRequest = new GetSourceTypeRequest(domain);

    return this.http.post<SourceTypeResponse>('/api/v1/sourceType/getSourceType', getSourceTypeRequest);
  }

  create(name: string, domain: string, ip?: string): Observable<SourceTypeResponse> {
    const createSourceTypeRequest = new CreateSourceTypeRequest(name, domain, ip);

    return this.http.post<SourceTypeResponse>('/api/v1/sourceType/createSourceType', createSourceTypeRequest);
  }

  update(name: string, domain: string, ip?: string): Observable<SourceTypeResponse> {
    const updateSourceTypeRequest = new UpdateSourceTypeRequest(name, domain, ip);

    return this.http.post<SourceTypeResponse>('/api/v1/sourceType/updateSourceType', updateSourceTypeRequest);
  }

  remove(domain: string): Observable<RemoveSourceTypeResponse> {
    const removeSourceTypeRequest = new RemoveSourceTypeRequest(domain);

    return this.http.post<RemoveSourceTypeResponse>('/api/v1/sourceType/removeSourceType', removeSourceTypeRequest);
  }

  updateStatus(domain: string, status: boolean): Observable<UpdateSourceTypeStatusResponse> {
    const updateSourceTypeStatusRequest = new UpdateSourceTypeStatusRequest(domain, status);

    return this.http.post<UpdateSourceTypeStatusResponse>('/api/v1/sourceType/updateStatus', updateSourceTypeStatusRequest);
  }
}
