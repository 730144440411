import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GetSummaryRequest, GetSummaryResponse, UpdateSummaryRequest, UpdateSummaryResponse, UpdateTotalSummaryRequest, UpdateTotalSummaryResponse } from '../models/summary';

@Injectable({
  providedIn: 'root'
})
export class SummaryService {
  constructor(private http: HttpClient) { }

  get(accountId: string, date = new Date()): Observable<GetSummaryResponse> {
    const getSummaryRequest = new GetSummaryRequest(accountId, date);

    return this.http.post<GetSummaryResponse>('/api/v1/summary/getSummary', getSummaryRequest);
  }

  update(accountId: string, balance: number, date = new Date()): Observable<UpdateSummaryResponse> {
    const updateSummaryRequest = new UpdateSummaryRequest(accountId, balance, date);

    return this.http.post<UpdateSummaryResponse>('/api/v1/summary/updateSummary', updateSummaryRequest);
  }

  updateTotal(date = new Date()): Observable<UpdateTotalSummaryResponse> {
    const updateTotalSummaryRequest = new UpdateTotalSummaryRequest(date);

    return this.http.post<UpdateTotalSummaryResponse>('/api/v1/summary/updateTotalSummary', updateTotalSummaryRequest);
  }
}
