import { ApiResponse } from './api';

export class SourceType {
  '_id': string;
  name: string;
  domain: string;
  ip?: string;
  status?: boolean;
  created?: Date;

  constructor(name: string, domain: string, ip?: string, status?: boolean) {
    this.name = name;
    this.domain = domain;
    this.ip = ip;
    this.status = status;
  }
}

export class SourceTypeResponse extends ApiResponse {
  result: SourceType;
}

export class SourceTypeListResponse extends ApiResponse {
  result: SourceType[];
}

export class GetSourceTypeParams {
  constructor(public domain: string) {}
}

export class GetSourceTypeRequest {
  params: GetSourceTypeParams;

  constructor(domain: string) {
    this.params = new GetSourceTypeParams(domain);
  }
}

export class CreateSourceTypeParams {
  constructor(public name: string, public domain: string, public ip?: string) {}
}

export class CreateSourceTypeRequest {
  params: CreateSourceTypeParams;

  constructor(name: string, domain: string, ip?: string) {
    this.params = new CreateSourceTypeParams(name, domain, ip);
  }
}

export class UpdateSourceTypeParams {
  constructor(public name: string, public domain: string, public ip?: string) {}
}

export class UpdateSourceTypeRequest {
  params: UpdateSourceTypeParams;

  constructor(name: string, domain: string, ip?: string) {
    this.params = new UpdateSourceTypeParams(name, domain, ip);
  }
}

export class RemoveSourceTypeParams {
  constructor(public domain: string) {}
}

export class RemoveSourceTypeRequest {
  params: RemoveSourceTypeParams;

  constructor(domain: string) {
    this.params = new RemoveSourceTypeParams(domain);
  }
}

export class RemoveSourceTypeResponse extends ApiResponse {
  result: boolean;
}

export class UpdateSourceTypeStatusParams {
  constructor(
    public domain: string,
    public status: boolean) {}
}

export class UpdateSourceTypeStatusRequest {
  params: UpdateSourceTypeStatusParams;

  constructor(domain: string, status: boolean) {
    this.params = new UpdateSourceTypeStatusParams(domain, status);
  }
}

export class UpdateSourceTypeStatusResponse extends ApiResponse {
  result: boolean;
}
