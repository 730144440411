import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-order-info-modal',
  templateUrl: './order-info-modal.component.html',
  styleUrls: ['./order-info-modal.component.scss'],
})
export class OrderInfoModalComponent implements OnInit {
  @Input() orders;
  titles = {
    _id: 'ID(MDB)',
    paymentProvider: 'Платежная система:',
    amount: 'Сумма',
    currency: 'Валюта',
    cardNumber: 'Номер карты',
    clientOrderId: 'Client Order ID',
    bid_id: 'ID Заявки',
    status: 'Статус',
    orderId: 'Order ID',
    createdAt: 'Дата создания',
  };
  constructor(public modal: NgbActiveModal) {}

  ngOnInit(): void {}
  orderElements(order) {
    const el = Object.entries(order).filter((e) => typeof e[1] !== 'object');

    return el;
  }
}
