import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Operator } from '../models/operator';
import {
  CreateShiftTypeRequest,
  CreateShiftTypeResponse,
  CreateWorkShiftRequest,
  CreateWorkShiftResponse,
  RemoveWorkShiftRequest,
  RemoveWorkShiftResponse,
  RemoveWorkShiftTypeRequest,
  RemoveWorkShiftTypeResponse,
  ShiftTypeListResponse,
  UpdateShiftTypeRequest,
  WorkShiftListResponse,
} from '../models/schedule';
import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { SalarySettings } from '../models/salary';

@Injectable({
  providedIn: 'root',
})
export class WorkScheduleService {
  constructor(private http: HttpClient) {}

  //Work Schedule Types
  createType(
    name: string,
    startTime: NgbTimeStruct,
    endTime: NgbTimeStruct
  ): Observable<CreateShiftTypeResponse> {
    const request = new CreateShiftTypeRequest(name, startTime, endTime);
    return this.http.post<CreateShiftTypeResponse>(
      'api/v1/workschedule/addtype',
      request
    );
  }

  removeType(typeId: string): Observable<RemoveWorkShiftTypeResponse> {
    const request = new RemoveWorkShiftTypeRequest(typeId);
    return this.http.post<RemoveWorkShiftTypeResponse>(
      'api/v1/workschedule/removetype',
      request
    );
  }
  typeList(): Observable<ShiftTypeListResponse> {
    return this.http.post<ShiftTypeListResponse>(
      'api/v1/workschedule/typelist',
      {}
    );
  }

  updateType(
    id: string,
    name: string,
    startTime: NgbTimeStruct,
    endTime: NgbTimeStruct,
    salarySettings: SalarySettings
  ): Observable<any> {
    const request = new UpdateShiftTypeRequest(
      id,
      name,
      startTime,
      endTime,
      salarySettings
    );
    return this.http.post('api/v1/workschedule/updatetype', request);
  }

  // Work schedule

  addWorkShift(
    day: Date,
    dayString: string,
    start: Date,
    end: Date,
    operator: Operator,
    operatorId: string,
    shiftTypeName: string,
    typeId: string
  ): Observable<CreateWorkShiftResponse> {
    const createShiftRequest = new CreateWorkShiftRequest(
      day,
      dayString,
      start,
      end,
      operator,
      operatorId,
      shiftTypeName,
      typeId
    );
    return this.http.post<CreateWorkShiftResponse>(
      'api/v1/workschedule/addworkshift',
      createShiftRequest
    );
  }
  removeWorkShift(shiftId: string): Observable<RemoveWorkShiftResponse> {
    const req = new RemoveWorkShiftRequest(shiftId);
    return this.http.post<RemoveWorkShiftResponse>(
      'api/v1/workschedule/removeworkshift',
      req
    );
  }

  // getUserWorkShiftsList(params): Observable<WorkShiftListResponse> {
  //   return this.http.post<WorkShiftListResponse>(
  //     'api/v1/workschedule/usershiftlist',
  //     params
  //   );
  // }

  updateShift(id: string, realStart: Date, realEnd: Date) {
    return this.http.post('api/v1/workschedule/updateshift', {
      shiftId: id,
      realStart,
      realEnd,
    });
  }

  getWorkShiftsList(
    dateFrom?: Date,
    dateTo?: Date,
    userIds?: string[],
    typeIds?: string[]
  ): Observable<WorkShiftListResponse> {
    return this.http.post<WorkShiftListResponse>(
      'api/v1/workschedule/shiftlist',
      { dateFrom, dateTo, userIds, typeIds }
    );
  }
}
