export default [
  { name: 'trade', title: 'Сделка' },
  { name: 'common', title: 'Транзакция' },
  { name: 'inner', title: 'Расход' },
  { name: 'innerInc', title: 'Доход' },
  { name: 'correction', title: 'Корректировка' },
  { name: 'innerIn', title: 'Ввод' },
  { name: 'innerOut', title: 'Вывод' },
  { name: 'innerExcluding', title: 'Расход Без Уч.' },
  { name: 'commonExcluding', title: 'Внутр.' },
];
