<div class="modal-header">
  <h4 class="modal-title">Детали платежа</h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="modal.dismiss('close')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <ngb-accordion #acc="ngbAccordion">
    <ngb-panel
      *ngFor="let order of orders; index as i"
      [id]="'order-info' + i"
      [title]="'Платежный ордер ' + (i + 1)"
    >
      <ng-template ngbPanelContent>
        <div class="card">
          <div class="card-body">
            <div class="border-bottom" *ngFor="let el of orderElements(order)">
              <span class="label font-weight-bold">{{ el[0] }}:</span>
              {{ el[1] }}
            </div>
          </div>
        </div>
        <hr class="danger" />

        <ul *ngIf="order.logs" class="list-group">
          Изменения статусов:
          <li
            *ngFor="let log of order.logs"
            class="
              list-group-item
              d-flex
              justify-content-between
              align-items-center
            "
          >
            {{ log.addToDbAt | date: "yyyy-MM-dd HH:mm:ss" }}
            <span class="badge badge-primary badge-pill">{{ log.status }}</span>
          </li>
        </ul>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>

  <!-- #region

  <div class="border-bottom" *ngFor="let el of orderElements()">
    <span class="label font-weight-bold">{{ titles[el[0]] || el[0] }}:</span>
    {{ el[1] }}
  </div>

  <hr class="danger" />
  Изменения статусов:
  <ul class="list-group">
    <li
      *ngFor="let log of order.logs"
      class="list-group-item d-flex justify-content-between align-items-center"
    >
      {{ log.addToDbAt | date: "yyyy-MM-dd HH:mm:ss" }}
      <span class="badge badge-primary badge-pill">{{ log.status }}</span>
    </li>
  </ul>
  -->
</div>
<div class="modal-footer">
  <button (click)="modal.dismiss('cancel')" type="button" class="btn btn-light">
    Закрыть
  </button>
</div>
