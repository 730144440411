import { ApiResponse } from './api';

export type SummaryType = 'trade' | 'common' | 'inner';
export type SummaryAction = 'create' | 'remove';

export class Summary {
  '_id': string;
  accountId: string;
  balanceAuto: number;
  balanceAutoUSDT: number;
  balanceReal: number;
  balanceRealUSDT: number;
  stringTS: string;
  timestamp: Date;
}

export class GetSummaryParams {
  constructor(
    public accountId: string,
    public date: Date) {}
}

export class GetSummaryRequest {
  params: GetSummaryParams;

  constructor(accountId: string, date: Date) {
    this.params = new GetSummaryParams(accountId, date);
  }
}

export class GetSummaryResponse extends ApiResponse {
  result: Summary;
}

export class UpdateSummaryParams {
  constructor(
    public accountId: string,
    public balance: number,
    public date: Date) {}
}

export class UpdateSummaryRequest {
  params: UpdateSummaryParams;

  constructor(accountId: string, balance: number, date: Date) {
    this.params = new UpdateSummaryParams(accountId, balance, date);
  }
}

export class UpdateSummaryResponse extends ApiResponse {
  result: boolean;
}

export class UpdateTotalSummaryParams {
  constructor(public date: Date) {}
}

export class UpdateTotalSummaryRequest {
  params: UpdateTotalSummaryParams;

  constructor(date: Date) {
    this.params = new UpdateTotalSummaryParams(date);
  }
}

export class UpdateTotalSummaryResponse extends ApiResponse {
  result: boolean;
}
