import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { ApiResponse } from './api';
import { Operator } from './operator';
import { CalendarEvent } from 'angular-calendar';
import { EventColor } from 'calendar-utils';
import { KpiRate, SalarySettings } from './salary';

// рабочая смена(запись на календаре)
export class WorkShift implements CalendarEvent {
  '_id': string;
  dayString: string;
  day: Date;
  typeId: string;
  operatorId: string;
  start: Date;
  end: Date;
  shiftTypeName: string;
  operator: Operator;
  author: Operator;
  created: Date;
  title: string;
  color: EventColor;
  realStart?: Date;
  realEnd?: Date;
}

export class WorkShiftType {
  '_id': string;
  name: string;
  startTime: NgbTimeStruct;
  endTime: NgbTimeStruct;
  salarySettings: SalarySettings;
  created: Date;
}

export class WorkShiftResponse extends ApiResponse {
  result: WorkShift;
}

export class WorkShiftListResponse extends ApiResponse {
  result: WorkShift[];
}

export class GetWorkShiftListParams {
  constructor() {}
}

export class GetWorkShiftListRequest {
  params: GetWorkShiftListParams;
  constructor() {}
}

export class CreateShiftTypeParams {
  constructor(
    public name: string,
    public startTime: NgbTimeStruct,
    public endTime: NgbTimeStruct
  ) {}
}

export class CreateShiftTypeRequest {
  params: CreateShiftTypeParams;
  constructor(name: string, startTime: NgbTimeStruct, endTime: NgbTimeStruct) {
    this.params = new CreateShiftTypeParams(name, startTime, endTime);
  }
}

export class UpdateShiftTypeParams {
  constructor(
    public id: string,
    public name: string,
    public startTime: NgbTimeStruct,
    public endTime: NgbTimeStruct,
    public salarySettings: SalarySettings
  ) {}
}

export class UpdateShiftTypeRequest {
  params: UpdateShiftTypeParams;
  constructor(
    id: string,
    name: string,
    startTime: NgbTimeStruct,
    endTime: NgbTimeStruct,
    salarySettings: SalarySettings
  ) {
    this.params = new UpdateShiftTypeParams(
      id,
      name,
      startTime,
      endTime,
      salarySettings
    );
  }
}

export class CreateShiftTypeResponse extends ApiResponse {
  result: boolean;
}

export class ShiftTypeListResponse extends ApiResponse {
  result: WorkShiftType[];
}

export class RemoveWorkShiftTypeParams {
  constructor(public typeId: string) {}
}

export class RemoveWorkShiftTypeRequest {
  params: RemoveWorkShiftTypeParams;
  constructor(typeId: string) {
    this.params = new RemoveWorkShiftTypeParams(typeId);
  }
}

export class RemoveWorkShiftTypeResponse {
  result: boolean;
}

export class CreateWorkShiftParams {
  constructor(
    public day: Date,
    public dayString: string,
    public start: Date,
    public end: Date,
    public operator: Operator,
    public operatorId: string,
    public shiftTypeName: string,
    public typeId: string
  ) {}
}

export class CreateWorkShiftRequest {
  params: CreateWorkShiftParams;
  constructor(
    day: Date,
    dayString: string,
    start: Date,
    end: Date,
    operator: Operator,
    operatorId: string,
    shiftTypeName: string,
    typeId: string
  ) {
    this.params = new CreateWorkShiftParams(
      day,
      dayString,
      start,
      end,
      operator,
      operatorId,
      shiftTypeName,
      typeId
    );
  }
}

export class CreateWorkShiftResponse extends ApiResponse {
  result: boolean;
}

export class RemoveWorkShiftParams {
  constructor(public shiftId: string) {}
}

export class RemoveWorkShiftRequest {
  params: RemoveWorkShiftParams;
  constructor(shiftId: string) {
    this.params = new RemoveWorkShiftParams(shiftId);
  }
}

export class RemoveWorkShiftResponse extends ApiResponse {
  result: boolean;
}
